<template>
  <v-container fluid class="pt-0 align-start fill-height">
    <MainModal
        :main="{ component: 'Regular', title: 'Нормативи' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_regular)"
        :modal="show_regular_dialog"
        @updateItemModal="regularUpdateItemModal"
    />
    <v-row style="height: 100%">
      <v-col cols="12" md="12">
        <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #fafafa; height: 100%"
                min-height="280">
          <v-card-title class="pa-0">
            <v-toolbar elevation="0" class="pa-0">
              <v-toolbar-title>
                Перелік нормативів
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom color="success">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="openRegularCreateDialog"
                         v-bind="attrs"
                         v-on="on"
                         class="grey lighten-4">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Створити новий норматив</span>
              </v-tooltip>
            </v-toolbar>
          </v-card-title>
          <v-data-table
              v-model="selected"
              :headers="tableHeader"
              :items="filteredItems"
              :single-select="true"
              :show-select="selectable"
              no-data-text="Дані відсутні. Жодного запису не виявлено"
              :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Рядків на сторінку',
                  itemsPerPageOptions: [15, 30, -1],

                }"
              class="custom-table custom-table-1"
              @click:row="onRegularItemClick"
              @item-selected="selectRow"
          >
            <template v-slot:item.icon="{ item }">
              <v-icon
                  size="26" class="mr-2"
                  :color="getAccountIcon(item.icon, 'color')"
              >
               {{ getAccountIcon(item.icon, 'icon') }}
              </v-icon>
            </template>
            <template v-slot:item.detalization="{ item }">
              {{ getRegularDetails(item) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {FETCH_REGULARS} from "@/store/actions/services";
import {getRegularDetails, getAccountIcon} from "@/utils/icons"

export default {
  props: ['selectable', 'model', 'service_id', 'social', 'is_legacy'],
  name: "RegularView",
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters({
      items: 'getRegulars'
    }),
    filteredItems() {
      if (!this.items) return []

      if (this.service_id) {
        return this.items.filter(item => item.service_id === this.service_id && item.social_normative === (this.social || false))
      } else {
        return this.items.filter(item => item.social_normative === (this.social || null))
      }
    }
  },
  data() {
    return {
      selected: [],
      tableHeader: [
        {text: '', value: 'icon', width: 26},
        {text: 'Послуга', value: 'service_name'},
        {text: 'Назва', value: 'name'},
        {text: 'Опис', value: 'detalization'},
      ],
      selected_regular: {},
      show_regular_dialog: false,
    }
  },
  methods: {
    ...mapActions({
      fetch: FETCH_REGULARS
    }),
    getRegularDetails,
    getAccountIcon,
    selectRow(payload) {
      this.$emit('selectedItemRow', payload.value ? payload.item : undefined)
    },
    regularUpdateItemModal() {
      this.show_regular_dialog = false
      this.selected_regular = {}
    },
    openRegularCreateDialog() {
      this.selected_regular = { service_id: this.service_id ? this.service_id : null, is_legacy: this.is_legacy || false }
      this.show_regular_dialog = true
    },
    onRegularItemClick(payload) {
      this.selected_regular = JSON.parse(JSON.stringify(payload))
      this.show_regular_dialog = true
    },
  },
  watch: {
    model(payload) {
      if (payload) {
        this.selected = []
        this.fetch({legacy: this.is_legacy || false})
      }
    }
  },
  created() {
    this.fetch({legacy: this.is_legacy || false})
  }
}
</script>

<style scoped>

</style>